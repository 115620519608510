@import "vars";
@import "mixins";

html,
body {
    margin: 0 auto;
    padding: 0;
    text-decoration: none; }

body {
    font-family: $bai;
    -ms-overflow-x: hidden;
    overflow-x: hidden; }

// Default
.list-unstyled {
    margin: 0;
    padding: 0;

    list-style: none; }

.tac {
    text-align: center; }

.sidebar {
    display: none; }

object {
    pointer-events: none; }

.cf {
    @include clearfix; }

// Buttons
input, button, textarea {
    outline: none;
    border-radius: 0;
    -webkit-appearance: none; }

a {
    text-decoration: none;
    transition: all 0.4s;

    &:hover {
        opacity: 0.7; } }

h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    font-weight: normal; }

ul {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none; } }

.article {
    h1 {
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        font-size: 2em; }

    h2 {
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em; }

    h3 {
        margin-block-start: 1em;
        margin-block-end: 1em;
        font-size: 1.17em; }

    h4 {
        margin-block-start: 1.33em;
        margin-block-end: 1.33em; }

    ul {
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        padding-inline-start: 40px;

        li {
            list-style-type: disc; } } }

.inp-group {
    input {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none; } }

.inp-error {
    input,
    textarea {
        border-bottom-color: #FF0000!important; } }

.txt-up {
    text-transform: uppercase; }

.visible-xs {
    display: none;

    @include tablet {
        display: block; } }

.inp-group {
    height: 51px;
    line-height: 51px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 19px 0 0;
    min-width: 304px;

    input {
        border: none;
        padding: 0;
        margin: 0;

        display: block;
        width: 100%;
        height: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid #C4C4C4;
        padding-left: 10px;

        font-family: $bai;
        font-weight: $bai-l;
        color: #000;
        font-size: 14px;

        @include placeholder-all {
            font-family: $bai;
            font-weight: $bai-l;
            color: #747474;
            font-size: 14px; } }

    &--textarea {
        height: 95px;

        textarea {
            padding: 0;
            margin: 0;
            resize: none;
            border: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            ouline: none;
            display: block;
            width: 100%;
            height: 100%;
            border-bottom: 1px solid #C4C4C4;
            padding-left: 10px;

            font-family: $bai;
            font-weight: $bai-l;
            color: #000;
            font-size: 14px;

            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            @include placeholder-all {
                font-family: $bai;
                font-weight: $bai-l;
                color: #747474;
                font-size: 14px; } } } }

.is-ovh {
    overflow: hidden; }

@media (min-width: 1025px) {
    .menu {
        display: none !important; }

    .top__l .header__l {
        border-right: none!important;

        &:after {
            position: absolute;
            content: ' ';
            top: 0;
            bottom: 0;
            right: 0;
            width: 1px;
            background: #727272;
            transition: all 0.4s; } }

    .header {
        &__socials {
            transition: all 0.4s;
            width: 200px;

            &:hover {
                cursor: pointer; }

            &-dec {
                transition: all 0.4s; }

            &-links {
                opacity: 0;
                pointer-events: none;
                transition: all 0.4s;
                transform: translate3d(-50px, 0, 0);
                position: absolute;
                left: 75px; } } }

    .top {
        &.socials {
            &--animated {
                .top__l .header__socials {
                    width: 225px!important; }

                .top__l {
                    .header__l {
                        &:after {
                            right: var(--width); } } }

                .header {
                    &__socials {
                        transform: none;

                        &-dec {
                            width: 0; }

                        &-links {
                            opacity: 1;
                            transform: translate3d(0px, 0, 0);
                            pointer-events: all; } } } } } } }

.top__slider-desc a {
    &:hover:focus,
    &:focus {
        outline: none; } }

.header__nav {
    ul {
        li {
            a {
                &:hover {
                    opacity: 1;
                    color: $color-primary!important; } } } } }

.page-name {
    margin: 0 !important; }

.top__c {
    min-height: 100vh; }

.wpcf7-response-output {
    display: none !important; }

.header__l--open {
    display: none; }

@media (max-width: 1024px) {
    div.wpcf7-response-output {
        display: none !important; }

    span.wpcf7-not-valid-tip {
        height: 24px;
        line-height: 24px;
        font-size: 12px!important; }

    .ajax-loader {
        display: none !important; }

    .header__l--open {
        display: block;
        position: absolute;
        top: 80px;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1001; }

    .news-item--adv {
        margin-bottom: 38px !important; } }



@media (min-width: 1500px) {
    body {
        .news-item--mini .news-item__img {
            height: 200px; }

        .news-item--big .news-item__img {
            height: 450px; } } }

@media (min-width: 1025px) and (max-width: 1450px) {
    .top__news {
        margin-left: -60px!important;
        margin-right: -60px!important; } }

@media (max-height: 600px) and (max-width: 600px) {
    .menu {
        padding-top: 70px!important; }

    .menu--opened .header__sign {
        top: 70px !important; }

    .menu__items--top {
        margin-bottom: 30px !important; } }

.loadmore {
    display: flex;
    justify-content: center;

    @media(max-width: 1024px) {
        margin-top: 20px; } }

.top__l .custom-logo-link {
    position: relative;
    z-index: 1001; }
