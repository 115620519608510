.news {
	&-items {}

	&-item {
		display: flex;
		margin-bottom: 38px;
		text-decoration: none;

		&__headline {
			font-family: $bai;
			font-size: 16px;
			font-weight: $bai-m;
			line-height: 150%;

			color: #000;

			margin: 0 0 18px 0; }

		&__desc {
			font-family: $bai;
			font-size: 14px;
			font-weight: $bai-l;
			line-height: 150%;
			color: #747474; }

		&__img {
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top; } }

		&__link {
			display: flex;
			-webkit-justify-content: flex-end;
			justify-content: flex-end;

			span {
				font-family: $bai;
				font-weight: $bai-l;
				font-size: 14px;
				position: relative;
				display: block;
				width: 100%;
				text-align: right;
				color: $color-primary;

				&:after {
					position: absolute;
					content: ' ';
					left: 0;

					top: 50%;
					transform: translate(0, -50%);
					height: 1px;
					background: #B1B1B1;
					right: 80px; } } }

		&--mini {
			padding-right: 40px;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;

			.news-item {
				&__img {
					width: 43%;
					height: 145px;
					padding-right: 30px; }

				&__desc {
					width: 57%; } } }

		&--big {
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;

			.news-item {
				&__img {
					width: 100%;
					margin-bottom: 13px; } } }

		&--adv {
			background: $color-primary;
			padding: 59px 0;
			text-align: center;

			span {
				text-transform: uppercase;
				font-family: $bai;
				font-weight: $bai-m;
				font-size: 14px;
				color: #fff;
				display: block;
				width: 100%;
				text-align: center; } } } }

.pagination {
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	margin-top: 100px;
	-webkit-justify-content: center;
	justify-content: center;

	&__arrow {
		width: 50px;
		height: 50px;
		background: transparent;
		border: 1px solid #B1B1B1;
		-webkit-box-sizing: border-box {
		  -moz-box-sizing: border-box;
		  box-sizing: border-box; }
		padding: 0;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		transition: all 0.4s;

		svg {
			path {
				transition: all 0.4s; } }

		&:hover {
			background: $color-primary;
			border: 1px solid $color-primary;
			cursor: pointer;

			svg {
				path {
					stroke: #fff; } } } }

	&__list {
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		position: relative;

		&:before {
			position: absolute;
			content: ' ';
			left: -45px;
			height: 1px;
			width: 130px;
			background: #727272;
			top: 50%;
			transform: translate(-100%, -50%); }

		&:after {
			position: absolute;
			content: ' ';
			right: -45px;
			height: 1px;
			width: 130px;
			background: #727272;
			top: 50%;
			transform: translate(100%, -50%); }

		li {
			a {
				font-family: $bai;
				font-weight: $bai-m;
				color: #000;
				font-size: 16px;
				text-decoration: none;
				padding: 0 3px; }

			&.is-active {
 				a {
 					position: relative;
 					top: -6px;
 					line-height: 1;
 					display: inline-block;
 					font-weight: $bai-b;
 					font-size: 36px;
 					color: $color-primary; } } } } }

.wp-pagenavi {
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	position: relative;

	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-justify-content: center;
	justify-content: center;

	&:before {
		position: absolute;
		content: ' ';
		left: 80px;
		height: 1px;
		width: 130px;
		background: #727272;
		top: 50%;
		transform: translate(0%, -50%);

		@media (max-width: 1024px) {
			display: none; } }

	&:after {
		position: absolute;
		content: ' ';
		right: 80px;
		height: 1px;
		width: 130px;
		background: #727272;
		top: 50%;
		transform: translate(0%, -50%);

		@media (max-width: 1024px) {
			display: none; } }

	.current {
		font-family: $bai;
		font-weight: $bai-m;
		color: #000;
		font-size: 16px;
		text-decoration: none;
		padding: 0 3px;

		position: relative;
		top: -6px;
		line-height: 1;
		display: inline-block;
		font-weight: $bai-b;
		font-size: 36px;
		color: $color-primary; }

	a {
		font-family: $bai;
		font-weight: $bai-m;
		color: #000;
		font-size: 16px;
		text-decoration: none;
		padding: 0 3px; }

	.nextpostslink {
		width: 50px;
		height: 50px;
		background: transparent;
		border: 1px solid #B1B1B1;
		-webkit-box-sizing: border-box {
		  -moz-box-sizing: border-box;
		  box-sizing: border-box; }
		padding: 0;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		transition: all 0.4s;
		position: absolute;
		right: 0;

		text-indent: -9999px;

		&:after {
			position: absolute;
			content: ' ';
			top: 50%;
			transform: translate(0, -50%);
			width: 20px;
			height: 15px;
			background: url('../img/icons/next-gray.svg') center center no-repeat;
			-webkit-background-size: contain {
			 -o-background-size: contain;
			 background-size: contain; }
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto; }

		&:hover {
			&:after {
				background: url('../img/icons/next-white.svg') center center no-repeat;
				-webkit-background-size: contain {
				 -o-background-size: contain;
				 background-size: contain; } } }

		svg {
			path {
				transition: all 0.4s; } }

		&:hover {
			background: $color-primary;
			border: 1px solid $color-primary;
			cursor: pointer;
			opacity: 1;

			svg {
				path {
					stroke: #fff; } } } }

	.previouspostslink {
		width: 50px;
		height: 50px;
		background: transparent;
		background-size: contain;
		border: 1px solid #B1B1B1;
		-webkit-box-sizing: border-box {
		  -moz-box-sizing: border-box;
		  box-sizing: border-box; }
		padding: 0;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		transition: all 0.4s;
		position: absolute;
		left: 0;
		text-indent: -9999px;

		&:after {
			position: absolute;
			content: ' ';
			top: 50%;
			transform: translate(0, -50%);
			width: 20px;
			height: 15px;
			background: url('../img/icons/prev-gray.svg') center center no-repeat;
			-webkit-background-size: contain {
			 -o-background-size: contain;
			 background-size: contain; }
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto; }

		&:hover {
			&:after {
				background: url('../img/icons/prev-white.svg') center center no-repeat;
				-webkit-background-size: contain {
				 -o-background-size: contain;
				 background-size: contain; } } }

		svg {
			path {
				transition: all 0.4s; } }

		&:hover {
			background: $color-primary;
			border: 1px solid $color-primary;
			cursor: pointer;
			opacity: 1;

			svg {
				path {
					stroke: #fff; } } } } }

@media (min-width: 1025px) {
	.form-footer {
		form {
			position: relative; } }

	.form-footer,
	.contact__form {
		form {
			display: flex;
			-webkit-align-items: center;
			align-items: center;

			&.invalid {
				.inp-group {
					input,
					textarea {
						border-bottom-color: red; } } } }

		// .ajax-loader,
		.wpcf7-validation-errors {
			display: none !important; }

		span.wpcf7-not-valid-tip {
			height: 40px;
			line-height: 40px;
			font-size: 14px; } }

	.ajax-loader {
		visibility: visible;
		display: block !important;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		left: 0;
		right: 0;
		margin: 0 auto; }

	.contact__form {
		form {
			-webkit-align-items: flex-end;
			align-items: flex-end; }

		span.wpcf7-not-valid-tip {
			height: 24px;
			line-height: 24px; } } }


