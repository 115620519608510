@import "vars";
@mixin br($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius; }

@mixin clearfix {
	&:after {
		content: ' ';

		visibility: hidden;
		display: block;
		height: 0;

		clear: both; } }

@mixin placeholder-all {
    &.placeholder {
    	@content; }
    &:-moz-placeholder {
    	@content; }
    &::-webkit-input-placeholder {
    	@content; } }

@mixin desktop-s {
    @media(max-width: 1280px) {
    	@content; } }

@mixin desktop-m {
    @media(max-width: 1440px) {
    	@content; } }

@mixin desktop-l {
    @media(max-width: 1920px) {
    	@content; } }

@mixin desktop-xl {
    @media(min-width: 1921px) {
    	@content; } }

@mixin tablet {
    @media(max-width: 1024px) {
    	@content; } }



